import { Vector3, Vector2 } from 'three'

export const Tau = Math.PI * 2
export const vec3 = (x, y, z) => new Vector3(x, y, z)
export const vec2 = (x, y) => new Vector2(x, y)

export const spread = a => {
  const n = Math.random() * a
  return Math.random() > 0.5 ? n : -n
}

export const spreadVec3 = a => vec3(spread(a), spread(a), spread(a))

export const rand = (min, max) => min + (max - min) * Math.random()

export const normDot = (a, b) => {
  const dot = a.dot(b)
  const max = Math.sqrt(a.lengthSq() * b.lengthSq())
  const norm = (dot + Math.abs(dot)) / 2
  return norm / max
}
