import { Tau, rand, spreadVec3, vec3, normDot } from '../math'
import { camera, scene, register, unregister } from '../world'
import {
  LineBasicMaterial,
  Line,
  Geometry,
  BufferGeometry,
  BufferAttribute,
  VertexColors,
  Color
} from 'three'

const numFlyers   = 100
const trailLength = 80
const maxInitDist = 0.1 // 0.1
const maxInitVel  = 0.03
const maxWander   = 100 // 10
const minPat      = 0.01
const maxPat      = 0.7
const minZip      = 0 // 0.01
const maxZip      = 0 // 0.03
const throttleMin = 0.001 // 0.00005
const throttleMax = 0.02 // 0.0008

let flyers = []

const makeBufferGeometry = (maxPositions, attrs) => {
  const geo = new BufferGeometry()

  Object.entries(attrs).forEach(([name, itemSize]) => {
    const array = new Float32Array(maxPositions * itemSize)
    geo.addAttribute(name, new BufferAttribute(array, itemSize))
  })

  return geo
}

const updateBufferGeometry = (geo, attrName, array) => {
  array.forEach((a, i) => geo.attributes[attrName].array[i] = a)
  geo.attributes[attrName].needsUpdate = true
  geo.setDrawRange(0, array.length / geo.attributes[attrName].itemSize)
}

const turn = (f, t) => {
  if (!f._target || Math.random() > 0.1) {
    f._target = spreadVec3(maxWander)
  }

  const diff = f._target.clone().sub(f.position)
  if (diff.lengthSq() <= 10) {
    f._target = null
  } else {
    f.accel = diff.clone().setLength(f.throttle)
  }

  const sorted_ = flyers.slice()

  sorted_.sort((a, b) => {
    return a.position.clone().sub(f.position).lengthSq()
  })

  const sorted = sorted_.slice(1)

  const swarmAvgVel = sorted.reduce((acc, a, i) => {
    return acc.clone().multiplyScalar(i).add(a.velocity).divideScalar(i + 1)
  }, vec3(0, 0, 0))

  const swarmAvgP = sorted.reduce((acc, a, i) => {
    return acc.clone().multiplyScalar(i).add(a.position).divideScalar(i + 1)
  }, vec3(0, 0, 0))


  const sin = Math.sin(t / 5000)
  // const swarminess_ = sin > 0 ? 0.05 : 0
  // const swarminess_ = 0.05

  const closest = sorted[0].position.clone().sub(f.position).lengthSq()
  const closiness = Math.min(closest / 10, 1)

  f.accel.add(swarmAvgVel.clone().sub(f.velocity).multiplyScalar(0.05))

  // f.accel.add(swarmAvgP.clone().sub(f.position).multiplyScalar(closiness * 0.01))

  f.accel.setLength(f.throttle)

  return

  // const wander  = f.position.lengthSq() / Math.pow(maxWander, 2)
  // const dotn    = normDot(f.velocity, f.accel)
  // const aligned = dotn > f.patience
  // const goBack  = (wander > 0.7 && Math.random() < wander) || f._goBack
//
  // if (goBack) {
  //   f._goBack = true
  //   if (f._howFar === null) f._howFar = rand(0, 0.3)
//
  //   if (Math.random() > 0.99 || f._detour !== null) {
  //     if (!f._detour) {
  //       f._detour = 0
  //       f._axis = spreadVec3(1)
  //     }
  //     f._detour++
  //     f.accel.applyAxisAngle(f._axis, Tau * 0.05 * f._detour)
  //     if (f._detour >= 100) f._detour = null
  //   } else {
  //     f.accel = f.position.clone().negate().setLength(f.throttle) // camera.position.clone().sub(f.position).setLength(f.throttle)
  //   }
//
  //   // if (wander < rand(0, 0.3)) f._goBack = false
  //   if (wander <= f._howFar) {
  //     f._goBack = false
  //     f._howFar = null
  //     return
  //   }
  // }
//
  // if (!aligned) return
//
  // f.zippy    = rand(minZip, maxZip)
  // f.throttle = rand(throttleMin, throttleMax)
  // f.patience = rand(minPat, maxPat)
//
  // f.accel.applyAxisAngle(spreadVec3(1), rand(0, Tau * 0.5))
  // f.accel.setLength(f.throttle)
}

const zippy = f => {
  f.accel.multiplyScalar(1 + f.zippy)
}

export const start = () => {
  flyers = (new Array(numFlyers).fill(null)).map(() => (
    {
      position: spreadVec3(maxInitDist),
      velocity: spreadVec3(maxInitVel),
      accel:    spreadVec3(throttleMax),
      throttle: rand(throttleMin, throttleMax),
      patience: rand(minPat, maxPat),
      zippy:    rand(minZip, maxZip),
      swarminess: 0.1,
      trail:    null,
      history:  []
    }
  ))

  const lineColor = new Color()
  lineColor.setHSL(Math.random(), 1, 0.7)

  flyers.forEach(f => {
    const geo = makeBufferGeometry(trailLength, { position: 3, color: 3 })
    f.trail = new Line(geo, new LineBasicMaterial({
      color: lineColor,
      vertexColors: VertexColors
    }))
    scene.add(f.trail)
  })

  camera.position.z = 1
  camera.fov = 150;
  camera.updateProjectionMatrix();

  register('flyers', t => {
    flyers.forEach(f => {
      f.velocity.add(f.accel)
      f.position.add(f.velocity)

      turn(f, t)
      zippy(f)

      // f.velocity.multiplyScalar(0.999)
      // const maxSpeed = 0.15
      // if (f.velocity.lengthSq() > Math.pow(maxSpeed, 2)) {
      //   f.velocity.setLength(maxSpeed)
      // }

      f.historyVec = [f.position].concat(f.historyVec || []).slice(0, trailLength)
      f.history = f.position.toArray().concat(f.history).slice(0, trailLength * 3)

      updateBufferGeometry(f.trail.geometry, 'position', f.history)
      updateBufferGeometry(f.trail.geometry, 'color', f.historyVec.reduce((acc, a, i) => {
        const alpha = 1 - (i / f.historyVec.length)
        const cameraDist = camera.position.clone().sub(a).lengthSq()
        const distDepth = 1 - Math.max(Math.min(cameraDist / Math.pow(6, 2), 1), 0)
        const value = Math.max(distDepth, 0.5) * alpha

        return [...acc, value, value, value]
      }, []))

      /* f.history.map((a, i) => (
        (trailLength - Math.ceil(i / 3) - 1) / trailLength
      ))) */

      // const cameraDist = camera.position.clone().sub(f.position).lengthSq()
      // const depth = Math.min(cameraDist / Math.pow(25, 2), 0.7)

      // const value = 1 - depth

      // f.trail.material.color.setRGB(value * 0.35, value, value)
    })

    camera.position.applyAxisAngle(vec3(0, 1, 0), 0.0009)
    camera.lookAt(vec3(0, 0, 0))

    // const posArr = flyers[0].history.slice(3, 6)
    // const lastP  = vec3(posArr[0], posArr[1], posArr[2])
    // const dir    = flyers[0].position.clone().add(lastP.clone().sub(flyers[0].position).negate())

    // camera.position.copy(flyers[0].position)
    // camera.lookAt(vec3(0, 0, 0))
  })
}

export const stop = () => {
  flyers.forEach(f => scene.remove(f.trail))
  unregister('flyers')
}

// Hot reloading

if (module.hot) {
  module.hot.dispose(stop)
}
