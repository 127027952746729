import { start } from './world'
import * as hexwave from './screensavers/hexwave'
import * as flying from './screensavers/flying'

start()

let current = flying
current.start()

// Hot reloading

if (module.hot) {
  module.hot.dispose(current.stop)

  module.hot.accept(() => {
    current.stop()
    current.start()
  })
}
