import { Tau, vec3, vec2, spread, rand } from '../math'
import { LineBasicMaterial, Line, Geometry } from 'three'
import { scene, camera, register, unregister } from '../world'
import polygon from '../polygon'
import repeat from '../repeat'

let shapes = []

export const start = () => {
  shapes = (new Array(150).fill(null)).map(() => (
    polygon(0x777777, rand(0.15, 0.8))
  ))

  shapes.forEach((c, i) => {
    const maxdist = 10
    scene.add(c)

    const newPos = repeat(1000, result => !!result, () => {
      const prior = shapes.slice(0, i)
      const pos = vec2(spread(maxdist), spread(maxdist))

      for (let j in prior) {
        const c2         = prior[j]
        const otherPos   = vec2(c2.position.x, c2.position.y)
        const distanceSq = pos.clone().sub(otherPos).lengthSq()
        const minDistSq  = Math.pow(c2._radius + c._radius, 2)

        if (distanceSq < minDistSq) return
      }

      return pos
    }) || vec2(spread(maxdist), spread(maxdist))

    c.position.x = newPos.x
    c.position.y = newPos.y

    c._phase   = rand(0, 3000)
    c._divisor = rand(400, 2300)
  })

  camera.position.z = 7

  register('hexwave', (t) => {
    shapes.forEach(c => {
      c.position.z = Math.sin((t + c._phase) / c._divisor)
      c.position.x = c.position.x + (Math.sin((t + c._phase) / c._divisor)) / 100
    })
  })
}

export const stop = () => {
  unregister('hexwave')
  shapes.forEach(c => scene.remove(c))
}

// Hot reloading

if (module.hot) {
  module.hot.dispose(stop)
}
