let timeout

const restartTimer = () => {
  clearTimeout(timeout)
  timeout = setTimeout(hideContainer, 8000)
}

const getContainer = () => {
  return document.querySelector('.container')
}

const hideContainer = () => {
  const c = getContainer()
  if (!c) return

  c.classList.add('hidden')
}

const showContainer = () => {
  const c = getContainer()
  if (!c) return

  c.classList.remove('hidden')
}

window.addEventListener('mousemove', () => {
  showContainer()
  restartTimer()
})

restartTimer()
