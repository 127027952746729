import { Tau, vec3 } from './math'
import { Line, Geometry, LineBasicMaterial } from 'three'

export default (color, radius = 1, segments = 6) => {
  const polygon = new Line(new Geometry(), new LineBasicMaterial({ color }))

  polygon.geometry.vertices = new Array(segments + 1).fill(null).map((_, i) => {
    const rad = Tau * i / segments
    return new vec3(Math.cos(rad) * radius, Math.sin(rad) * radius, 0)
  })

  polygon.geometry.verticesNeedUpdate = true
  polygon._radius = radius

  return polygon
}
