import {
  Scene,
  PerspectiveCamera,
  WebGLRenderer
} from 'three'

export const renderer = window._renderer || new WebGLRenderer()
export const scene    = new Scene()
export const camera   = new PerspectiveCamera(75, 1.3, 0.1, 1000)
export const render   = () => renderer.render(scene, camera)

// Dimensions

const syncWindowSize = () => {
  renderer.setSize(window.innerWidth, window.innerHeight)
  camera.aspect = window.innerWidth / window.innerHeight
  camera.updateProjectionMatrix()
  render()
}

// Loop

let requestId
let loopLock
let paused = false
let tasks = {}

const loop = (lock, timestamp) => {
  if (paused) return

  if (!loopLock) {
    loopLock = Math.random()
  } else if (lock !== loopLock) {
    return
  }

  requestId = requestAnimationFrame(timestamp => loop(loopLock, timestamp))

  if (timestamp) Object.values(tasks).forEach(fn => fn(timestamp))

  render()
}

export const pause = () => {
  paused   = true
  loopLock = null
  cancelAnimationFrame(requestId)
}

export const resume = () => {
  if (!paused) return
  paused = false
  loop()
}

export const start = loop

export const register = (name, fn) => {
  tasks[name] = fn
}

export const unregister = name => {
  delete tasks[name]
}

// Events

window.addEventListener('resize', syncWindowSize)

// Initialize

window.addEventListener('load', () => {
  document.body.appendChild(renderer.domElement)
  syncWindowSize()
})

// Hot reloading

if (module.hot) {
  module.hot.dispose(() => {
    pause()
    window._renderer = renderer
  })
}
